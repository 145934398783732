import { useEffect, useState } from 'react'

/**
 * This hook returns the current timestamp and is updated
 * at the requested period.
 *
 * @param updatePeriodMs the interval between two updates
 */
export function useCurrentTimestamp(updatePeriodMs: number): number {
  const [timestamp, setTimestamp] = useState(Date.now())

  useEffect(() => {
    const intervalVariable = setInterval(() => {
      setTimestamp(Date.now)
    }, updatePeriodMs)

    return () => {
      clearInterval(intervalVariable)
    }
  }, [updatePeriodMs])

  return timestamp
}

export const oneSecond = 1000
export const oneMinute = 60 * oneSecond
